@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-mainVisual {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; }
  #top .p-mainVisual__bg {
    width: 100%;
    height: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; }
  #top .p-mainVisual__logo {
    height: 100%;
    display: flex;
    align-items: center;
    width: 90px;
    margin: 0 auto; }
    #top .p-mainVisual__logo img {
      width: 100%; }
  #top .p-mainVisual__swipe {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: calc(436 / 1063 * 100%);
    background-color: #FFFFFF;
    line-height: 0; }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__swipe {
        top: 23px;
        transform: none;
        width: 296px; } }
    #top .p-mainVisual__swipe-inner {
      width: 100%;
      height: 100%; }
    #top .p-mainVisual__swipe-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: -125px; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__swipe-wrap {
          right: -30px; } }
    #top .p-mainVisual__swipe-item {
      width: 100%;
      height: 23.6vw;
      max-height: 500px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__swipe-item {
          height: 205px; } }
  #top .p-mainVisual #newslist {
    position: absolute;
    bottom: 0;
    right: 0; }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual #newslist {
        bottom: 0; } }
  #top .p-mainVisual__scroll {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 105px;
    right: -55px;
    transform: rotate(90deg); }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__scroll {
        bottom: 125px;
        right: -68px; } }
    #top .p-mainVisual__scroll-txt {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05em;
      display: inline-block;
      margin-right: 12px; }
    #top .p-mainVisual__scroll-bar {
      display: inline-block;
      width: 100px;
      height: 2px;
      background-color: #CCCCCC;
      position: relative;
      overflow: hidden; }
      #top .p-mainVisual__scroll-bar:before {
        content: '';
        width: 50%;
        height: 2px;
        position: absolute;
        top: 0;
        left: -50%;
        display: inline-block;
        background-color: #000660; }

#top #wrapper {
  padding: 0 0; }
  @media screen and (max-width: 1168px) {
    #top #wrapper {
      padding: 60px 0 0; } }

#top #newslist {
  padding-bottom: 0;
  max-width: 400px;
  padding-right: 0; }
  #top #newslist .c-newslist {
    display: block !important; }
    #top #newslist .c-newslist dl {
      flex-direction: column; }
      @media screen and (max-width: 768px) {
        #top #newslist .c-newslist dl dt {
          margin-bottom: 5px; } }
      #top #newslist .c-newslist dl dt .c-newslist__date {
        color: #FFFFFF; }
      #top #newslist .c-newslist dl dt .c-label {
        background-color: #FFFFFF;
        color: #000660; }
      #top #newslist .c-newslist dl dd {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #FFFFFF; }

#top .p-about .l-col2__detail {
  position: relative; }

#top .p-reason .l-col2__detail {
  position: relative; }
  #top .p-reason .l-col2__detail:before {
    content: '';
    display: block;
    background: url("/inc/image/top/img_loader.jpg") no-repeat center/contain;
    width: 968px;
    height: 519px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: -1; }
    @media screen and (max-width: 768px) {
      #top .p-reason .l-col2__detail:before {
        width: 658px;
        height: 357.7px; } }
    @media screen and (max-width: 568px) {
      #top .p-reason .l-col2__detail:before {
        width: 484px;
        height: 259.5px; } }

#top .l-fullimg__text {
  max-width: 640px; }

#top .l-fullimg__heading {
  font-style: italic;
  color: white; }

#top .l-fullimg__detail {
  color: white; }
